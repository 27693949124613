import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/service/banner.png'
class CaseStudyContainer extends Component {
    render() {
        return (
            <>
                <BannerImage
                    activeTab="Case Study"
                    bannerImage={banner}
                    text="We are very satisfied with the ergonomics of the new CRM.
                    The open communication with Persistent and its capacity to always
                    stay focused on our objectives has added real value to the success of
                    this project." />
                <Container fluid>
                    <Container className="py-5 border-bottom">
                        <Row>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>The</span> Customer
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                    In the 1990s, Geneviève, Marc, and Christophe
                                    Melkonian joined the company founded twenty
                                    years earlier by their parents. As co-chairs, they
                                    were determined to continue Aramine’s international
                                    growth. In just a few years, they’ve realised their
                                    ambition. Now represented in ninety countries,
                                    Aramine is a world-leading manufacturer of mining
                                    and underground equipment.
                            </p>
                                <p className="industries-paragrapgh">
                                    In 2019, proud of their success and determined to
                                    see the family business maintain its leading position,
                                    the co-directors took the next step. They decided to
                                    replace the in-house CRM and provide their teams
                                    with a modern solution appropriate for Aramine’s
                                    current successes and worthy of the results to come.
                            </p>
                                <p className="industries-paragrapgh">
                                    Aramine’s Technical Director, Emmanuel Voisin,
                                    turned to Persistent, a Salesforce integrator for more
                                    than 17 years with proven technical expertise and
                                    impeccable production industry references in the
                                    machinery and automotive sectors. Initial discussions
                                    confirmed that Persistent was the right partner for
                                    such a major project as they understood Aramine’s
                                    challenges from the outset.
                            </p>
                            </Col>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>Key</span> Information
                                    </h3>
                                <Container className="pt-3">
                                    <Row>
                                        <Col className="standard-background mt-2 p-3">
                                            <h3>Licenses</h3>
                                            <h1>37</h1>
                                            <p>users</p>
                                        </Col>
                                        <Col className="standard-background ml-2 mt-2 p-3">
                                            <h3>Duration</h3>
                                            <h1>7</h1>
                                            <p>months</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="standard-background mt-2 p-3">
                                            <h3>Integrations</h3>
                                            <h1>ERP</h1>
                                            <p>(prolog)</p>
                                        </Col>
                                        <Col className="standard-background ml-2 mt-2 p-3">
                                            <h3>Products</h3>
                                            <p>sales</p>
                                            <p>cloud</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="p-3 standard-background mt-3 p-3">
                                            <h6 className="font-weight-bold">Proposed integrations</h6>
                                            <p>Pardot, CPQ, Community Cloud</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="p-3 standard-background mt-3 p-3">
                                            <p>www.abc.com</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>

                    <Container className="py-5 border-bottom">
                        <Row>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>Key</span> Challenge
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                    <b className="text-standard">Increase flexibility</b> : Aramine needed to replace an
                                        inflexible in-house CRM with a system that worked
                                        seamlessly for all the intermediaries in their sector.
                                        The system needed to address issues in traceability
                                        and reconciliation. They also wanted to simplify the
                                        management and monitoring of machine and spare
                                        parts sales teams and increase access to weekly
                                        reports.
                            </p>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">Strengthen security</b> :  The in-house CRM
                                        allowed access to all of Aramine’s customer files.
                                        Consequently, it was forbidden to log into the CRM
                                        outside the office. This was a massive constraint for
                                        highly mobile sales teams working in over 90% of
                                        Aramine’s market outside Europe. On the road, sales
                                        teams were forced to create monitoring reports in
                                        MS Word and email them every evening.
                            </p>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">Free up time for the IT department</b> : As is often
                                        the case with any IT solution developed in-house,
                                        Aramine’s CRM generated a significant workload for
                                        its IT teams. An overwhelming demand for
                                        maintenance and support meant IT employees often
                                        struggled to progress other company projects.
                            </p>
                            </Col>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>Key</span> Solution
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                    <b className="text-standard">\</b>&nbsp;&nbsp;&nbsp;&nbsp;  Aramine needed to replace an
                                        inflexible in-house CRM with a system that worked
                                        seamlessly for all the intermediaries in their sector.
                                        The system needed to address issues in traceability
                                        and reconciliation. They also wanted to simplify the
                                        management and monitoring of machine and spare
                                        parts sales teams and increase access to weekly
                                        reports.
                            </p>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">\</b>&nbsp;&nbsp;&nbsp;&nbsp;   The in-house CRM
                                        allowed access to all of Aramine’s customer files.
                                        Consequently, it was forbidden to log into the CRM
                                        outside the office. This was a massive constraint for
                                        highly mobile sales teams working in over 90% of
                                        Aramine’s market outside Europe. On the road, sales
                                        teams were forced to create monitoring reports in
                                        MS Word and email them every evening.
                            </p>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">\</b> &nbsp;&nbsp;&nbsp;&nbsp;  As is often
                                        the case with any IT solution developed in-house,
                                        Aramine’s CRM generated a significant workload for
                                        its IT teams. An overwhelming demand for
                                        maintenance and support meant IT employees often
                                        struggled to progress other company projects.
                            </p>
                            </Col>

                        </Row>

                    </Container>
                    <Container className="py-5">
                        <Row>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>Key</span> Result
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                    Users of the new CRM praise its ergonomics and
                                    appreciate its flexibility. For Aramine, the benefits
                                    of Salesforce are multiple:
                                </p>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">\</b>&nbsp;&nbsp;&nbsp;&nbsp;  Significant time savings for salespeople have
                                    freed up hours for acquiring new leads and
                                    supporting existing customers
                            </p>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">\</b>&nbsp;&nbsp;&nbsp;&nbsp;   More responsive, efficient case management
                                    has increased customer satisfaction and loyalty
                            </p>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">\</b> &nbsp;&nbsp;&nbsp;&nbsp;  Fewer oversights and increased productivity
                                    as a result of optimising task allocation and
                                    management systems
                            </p>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">\</b> &nbsp;&nbsp;&nbsp;&nbsp;  Real-time updates to official reports have
                                    reduced the risk of errors and increased
                                    accessibility
                            </p>
                                <p className="industries-paragrapgh">
                                    This project was accomplished on budget with
                                    zero unforeseen impacts on Aramine’s finances.
                            </p>
                            </Col>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>Next</span> Steps
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                    As is often
                                    the case with any IT solution developed in-house,
                                    Aramine’s CRM generated a significant workload for
                                    its IT teams. An overwhelming demand for
                                    maintenance and support meant IT employees often
                                    struggled to progress other company projects.
                            </p>
                                <p className="industries-paragrapgh">
                                    As is often
                                    the case with any IT solution developed in-house,
                                    Aramine’s CRM generated a significant workload for
                                    its IT teams. An overwhelming demand for
                                    maintenance and support meant IT employees often
                                    struggled to progress other company projects.
                            </p>
                                <Col>
                                    <Container className="standard-background p-3">
                                        <Row>
                                            <Col md="2" style={{ fontSize: '60px', fontWeight: 'bold' }}>
                                                1
                                    </Col>
                                            <Col md="10" className="pt-3">
                                                <h6 className="font-weight-bold">Pardot</h6>
                                                <p>Salesforce’s Marketing Campaign Automation Solution</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container className="standard-background mt-3 p-3">
                                        <Row>
                                            <Col md="2" style={{ fontSize: '60px', fontWeight: 'bold' }}>
                                                2
                                    </Col>
                                            <Col md="10" className="pt-3">
                                                <h6 className="font-weight-bold">CPQ</h6>
                                                <p>an offer configurator for machine sales</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container className="standard-background mt-3 p-3">
                                        <Row>
                                            <Col md="2" style={{ fontSize: '60px', fontWeight: 'bold' }}>
                                                3
                                    </Col>
                                            <Col md="10" className="pt-3">
                                                <h6 className="font-weight-bold">Community Cloud</h6>
                                                <p>a customer portal for digital collaboration between Aramine and its customers</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                    <hr />
                </Container>

            </>
        )
    }
}

export default CaseStudyContainer