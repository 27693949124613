import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import CaseStudyContainer from "../containers/CaseStudyContainer"

const CaseStudy = (props) => (
  <Layout 
  pageInfo={{ pageName: "Case Study" }} 
  styleClass="home-page" 
  location={'/casestudy/'}>
    <SEO title="Case Study" />
    <CaseStudyContainer />
  </Layout>
)

export default CaseStudy
